import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState(null);

  useEffect(() => {
    // Get auth data from session storage on initial load
    const storedAuthData = sessionStorage.getItem('authData');
    if (storedAuthData) {
      setAuthData(JSON.parse(storedAuthData));
    }
  }, []);

  const login = (data) => {
    const authDataToStore = {
      email: data.email,
      token: data.token,
      userName: data.userName
    };
    sessionStorage.setItem('authData', JSON.stringify(authDataToStore));
    setAuthData(authDataToStore);
  };

  const logout = () => {
    // Remove all items from session storage
    sessionStorage.clear();
    // Reset authData state
    setAuthData(null);
  };

  return (
    <AuthContext.Provider value={{ authData, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};