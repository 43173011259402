import React, { useEffect, useState } from "react";
import "./App.css";
import "../src/Components/Loader.css";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import Virtual from "./Components/Blog/Virtual.js";
import WebBlog from "./Components/Blog/WebBlog.js";
import Contact from "./Components/Contact/Contact.js";

const Test = React.lazy(() => import("./Components/Contact/Test.js"));
const Header = React.lazy(() => import("./Components/Global/Header"));
const Footer = React.lazy(() => import("./Components/Global/Footer"));
const PrivacyStatement = React.lazy(() =>
  import("./Components/Global/PrivacyStatement")
);
const CookiePolicy = React.lazy(() =>
  import("./Components/Global/CookiePolicy")
);
const RefundPolicy = React.lazy(() =>
  import("./Components/Global/RefundPolicy")
);
const ScrollToTop = React.lazy(() => import("./Components/ScrollToTop"));
const GoToTop = React.lazy(() => import("./Components/GoToTop"));

const Home = React.lazy(() => import("./Components/Home/Home"));
const HomeIN = React.lazy(() => import("./Components/Home/HomeIN.js"));
const HomeAE = React.lazy(() => import("./Components/Home/HomeAE.js"));
const HomeCA = React.lazy(() => import("./Components/Home/HomeCA.js"));
const HomeGB = React.lazy(() => import("./Components/Home/HomeGB.js"));
const HomeSG = React.lazy(() => import("./Components/Home/HomeSG.js"));
const HomeUS = React.lazy(() => import("./Components/Home/HomeUS.js"));

const Image1 = React.lazy(() => import("./Components/Home/Image1.js"));
const NewSection = React.lazy(() => import("./Components/Home/NewSection.js"));

const About = React.lazy(() => import("./Components/About/About"));
const AboutAE = React.lazy(() => import("./Components/About/AboutAE.js"));
const AboutCA = React.lazy(() => import("./Components/About/AboutCA.js"));
const AboutGB = React.lazy(() => import("./Components/About/AboutGB.js"));
const AboutIN = React.lazy(() => import("./Components/About/AboutIN.js"));
const AboutSG = React.lazy(() => import("./Components/About/AboutSG.js"));
const AboutUS = React.lazy(() => import("./Components/About/AboutUS.js"));

const Blog = React.lazy(() => import("./Components/Blog/Blog"));
const Blog2 = React.lazy(() => import("./Components/Blog/Blog2"));
const Awsblog = React.lazy(() => import("./Components/Blog/Awsblog"));
const Servicenow = React.lazy(() => import("./Components/Blog/Servicenow.js"));
const Mobileapp = React.lazy(() => import("./Components/Blog/Mobileapp"));
const Salesforceapi = React.lazy(() =>
  import("./Components/Blog/Salesforceapi")
);
const Socialmedia = React.lazy(() => import("./Components/Blog/Socialmedia"));
const Pro6tips = React.lazy(() => import("./Components/Blog/Pro6tips"));
const InspiringDigital = React.lazy(() =>
  import("./Components/Blog/InspiringDigital")
);
const RemoteWorking = React.lazy(() =>
  import("./Components/Blog/RemoteWorking")
);
const ProductVisualisation = React.lazy(() =>
  import("./Components/Blog/ProductVisualisation")
);
const DedicatedRemoteDeveloper = React.lazy(() =>
  import("./Components/Blog/DedicatedRemoteDeveloper")
);
const Top10software = React.lazy(() =>
  import("./Components/Blog/Top10software")
);
const UnlockingtheFuture = React.lazy(() =>
  import("./Components/Blog/UnlockingtheFuture ")
);
const Service = React.lazy(() => import("./Components/Service/Service"));
const Insights = React.lazy(() => import("./Components/Insights/Insights"));

const Contactnew = React.lazy(() => import("./Components/Contact/Contactnew"));
const ContactAE = React.lazy(() => import("./Components/Contact/ContactAE.js"));
const ContactCA = React.lazy(() => import("./Components/Contact/ContactCA.js"));
const ContactGB = React.lazy(() => import("./Components/Contact/ContactGB.js"));
const ContactIN = React.lazy(() => import("./Components/Contact/ContactIN.js"));
const ContactSG = React.lazy(() => import("./Components/Contact/ContactSG.js"));
const ContactUS = React.lazy(() => import("./Components/Contact/ContactUS.js"));

const Digitalmarketing = React.lazy(() =>
  import("./Components/Service/Digitalmarketing")
);
const Industry = React.lazy(() => import("./Components/Industry/Industry"));
const Mernstackdevelopment = React.lazy(() =>
  import("./Components/Service/Mernstackdevelopment")
);
const Uiux = React.lazy(() => import("./Components/Service/Uiux"));
const Webdevelopment = React.lazy(() =>
  import("./Components/Service/webdevelopment")
);
const Mobiledevelopment = React.lazy(() =>
  import("./Components/Service/Mobiledevelopment")
);
const Ecomdevelopment = React.lazy(() =>
  import("./Components/Service/Ecomdevelopment")
);
const Datascience = React.lazy(() =>
  import("./Components/Emerging/Datascience")
);
const Applicationservices = React.lazy(() =>
  import("./Components/Applicationservices")
);
const Staffing = React.lazy(() => import("./Components/Staffing"));
const Itconsulting = React.lazy(() => import("./Components/Itconsulting"));
const Emerging = React.lazy(() => import("./Components/Emerging"));
const Webdesignanddevelopment = React.lazy(() =>
  import("./Components/Service/Webdesignanddevelopment")
);
const Enterprisesolutions = React.lazy(() =>
  import("./Components/Service/Enterprisesolutions")
);
const Qatesting = React.lazy(() => import("./Components/Service/Qatesting"));
const Graphicsdesign = React.lazy(() =>
  import("./Components/Service/Graphicsdesign")
);
const Wordpressdevelopment = React.lazy(() =>
  import("./Components/Service/Wordpressdevelopment")
);
const Phpdevelopment = React.lazy(() =>
  import("./Components/Service/Phpdevelopment")
);
const ECommerce = React.lazy(() => import("./Components/Service/ECommerce"));
const RecruitmentService = React.lazy(() =>
  import("./Components/Service/RecruitmentService")
);
const StaffAugementation = React.lazy(() =>
  import("./Components/Service/StaffAugementation")
);
const Staffingservices = React.lazy(() =>
  import("./Components/Service/Staffingservices")
);
const Recrutimentservices = React.lazy(() =>
  import("./Components/Service/Recrutimentservices")
);
const HealthCare = React.lazy(() => import("./Components/Industry/Healthcare"));
const Hospitility = React.lazy(() =>
  import("./Components/Industry/Hospitility")
);
const Banking = React.lazy(() => import("./Components/Industry/Banking"));
const Education = React.lazy(() => import("./Components/Industry/Education"));
const HighTechnology = React.lazy(() =>
  import("./Components/Industry/HighTechnology")
);
const Retail = React.lazy(() => import("./Components/Industry/Retail"));
const Automotive = React.lazy(() => import("./Components/Industry/Automotive"));
const Utilities = React.lazy(() => import("./Components/Industry/Utilities"));
const Insurance = React.lazy(() => import("./Components/Industry/Insurance"));
const MediaEntertainment = React.lazy(() =>
  import("./Components/Industry/MediaEntertainment")
);
const TourTravel = React.lazy(() => import("./Components/Industry/TourTravel"));
const AerospaceDefence = React.lazy(() =>
  import("./Components/Industry/AerospaceDefence")
);
const Career = React.lazy(() => import("./Components/Career/Career"));
const SearchEngineOptimization = React.lazy(() =>
  import("./Components/DigitalMarketing/SearchEngineOptimization")
);
const PayPerClick = React.lazy(() =>
  import("./Components/DigitalMarketing/PayPerClick")
);
const BlockChain = React.lazy(() => import("./Components/Emerging/Blockchain"));
const Automation = React.lazy(() => import("./Components/Emerging/Automation"));
const Extendedreality = React.lazy(() =>
  import("./Components/Emerging/Extendedreality")
);
const Cognitivecomputing = React.lazy(() =>
  import("./Components/Emerging/Cognitivecomputing")
);
const Cloud = React.lazy(() => import("./Components/Emerging/Cloud"));
const AI = React.lazy(() => import("./Components/Emerging/AI"));
const SocialMediaMarketing = React.lazy(() =>
  import("./Components/DigitalMarketing/SocialMediaMarketing")
);
const ContentMarketing = React.lazy(() =>
  import("./Components/DigitalMarketing/ContentMarketing")
);
const EmailMarketing = React.lazy(() =>
  import("./Components/DigitalMarketing/EmailMarketing")
);
const CustomDevelopment = React.lazy(() =>
  import("./Components/Service/CustomDevelopment")
);
const CMS = React.lazy(() => import("./Components/Service/CMS"));
const API = React.lazy(() => import("./Components/Service/API"));
const Salesforce = React.lazy(() =>
  import("./Components/Consulting/Salesforce.js")
);
const DigitalTransformation = React.lazy(() =>
  import("./Components/Consulting/DigitalTransformation.js")
);
const DevOps = React.lazy(() => import("./Components/Consulting/DevOps.js"));
const Workday = React.lazy(() => import("./Components/Consulting/Workday.js"));
const Jira = React.lazy(() => import("./Components/Consulting/Jira.js"));
const Kronos = React.lazy(() => import("./Components/Consulting/Kronos.js"));
const Consulting = React.lazy(() =>
  import("./Components/Consulting/Consulting.js")
);
const ServiceNow = React.lazy(() =>
  import("./Components/Consulting/ServiceNow.js")
);
const ErrorPage = React.lazy(() => import("./Components/ErrorPage"));
const CaseStudy = React.lazy(() => import("./Components/CaseStudy"));
const Casestudyworkday = React.lazy(() =>
  import("./Components/Casestudyworkday")
);
const Casestudydevops = React.lazy(() =>
  import("./Components/Casestudydevops")
);
const Casestudysalesforce = React.lazy(() =>
  import("./Components/Casestudysalesforce")
);
const Casestudyservicenow = React.lazy(() =>
  import("./Components/Casestudyservicenow")
);
const Information = React.lazy(() =>
  import("./Components/Information/Information")
);
const Amitsir = React.lazy(() => import("./Components/Information/Amitsir"));
const Himanshu = React.lazy(() => import("./Components/Information/Himanshu"));
const Login = React.lazy(() => import("./Components/Login/Login"));
const LogApi = React.lazy(() => import("./Components/Login/LogApi"));
const ContactNewDashboard = React.lazy(() =>
  import("./Components/Login/ContactNewDashboard")
);
const CareerDashboard = React.lazy(() =>
  import("./Components/Login/CareerDashboard")
);
const HomeContactDashboard = React.lazy(() =>
  import("./Components/Login/HomeContactDashboard")
);
const SideBar = React.lazy(() => import("./Components/Login/SideBar"));
const Carr = React.lazy(() => import("./Components/Career/Carr"));
const NewContactDash = React.lazy(() =>
  import("./Components/Login/NewContactDash")
);
const NewHomeDash = React.lazy(() => import("./Components/Login/NewHomeDash"));
const NewCareerDash = React.lazy(() =>
  import("./Components/Login/NewCareerDash")
);
const Projectlogin = React.lazy(() =>
  import("./Components/Login/Loginproject.jsx")
);
const Dashboard = React.lazy(() => import("./Components/Login/Home.jsx"));
const Companydashboard = React.lazy(() =>
  import("./Components/Information/Companydashboard.js")
);
const ServiceStudynew = React.lazy(() =>
  import("./Components/Servicenowstudy.js")
);
const AddBlog = React.lazy(() => import("./Components/Login/AddBlog.js"));
const ManageBlog = React.lazy(() => import("./Components/Login/ManageBlog.js"));
const AddJob = React.lazy(() => import("./Components/Login/AddJob.js"));
const ManageJob = React.lazy(() => import("./Components/Login/ManageJob.js"));
const BlogDetail = React.lazy(() => import("./Components/Blog/BlogDetails.js"));
const AllJobApplications = React.lazy(() =>
  import("./Components/Login/AllJobApplications.js")
);

function AppContent() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const excludedRoutes = [
    "/NewHomeDash",
    "/NewContactDash",
    "/NewCareerDash",
    "/test",
    "/AddBlog",
    "/ManageBlog",
    "/AddJob",
    "/ManageJob",
    "/AllJobApplications",
  ];

  useEffect(() => {
    // Simple loading simulation
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  const shouldShowHeaderFooter = !excludedRoutes.includes(location.pathname);

  return (
    <>
      <React.Suspense
        fallback={
          <div className="d-flex justify-content-center align-items-center"></div>
        }
      >
        <ScrollToTop />
        {shouldShowHeaderFooter && <Header />}
        <Routes>
          <Route path="/" element={<HomeCA />} />
          <Route path="/error-page" element={<ErrorPage />} />
          <Route path="*" element={<ErrorPage />} />
          <Route path="/about-us" element={<AboutCA />} />
          <Route path="/blogs" element={<Blog />} />
          <Route path="/blog/the-power-of-servicenow-in-business-service-management" element={<Servicenow />} />
          <Route path="/blog/crafting-a-winning-mobil-app-project" element={<Mobileapp />} />
          <Route path="/blog/business-operations-by-integration-of-salesforce" element={<Salesforceapi />} />
          <Route path="/blog/virtual-try-on-apps-development-revolutionizing-online-shopping-experience" element={<Virtual />} />
          <Route path="/blog/web-2.0-vs-web-3.0-a-digital-renaissance" element={<WebBlog />} />
          <Route path="/blog/crucial-social-media-marketing-principles" element={<Socialmedia />} />
          <Route path="/blog/pro-tips-for-selecting-the-top-mobile-app-development-company" element={<Pro6tips />} />
          <Route path="/blog/digital-transformation-example-to-guide-your-strategy" element={<InspiringDigital />} />
          <Route path="/blog/grow-your-business-with-dedicated-remote-developer-in-2023" element={<RemoteWorking />} />
          <Route path="/blog/how-can-extended-reality-be-used-for-product-visualisation" element={<ProductVisualisation />} />
          <Route path="/blog/secrets-of-remote-working-to-drive-productivity" element={<DedicatedRemoteDeveloper />} />
          <Route path="/blog/software-development-trends-in-2023" element={<Top10software />} />
          <Route path="/blog/benefits-of-application-development" element={<UnlockingtheFuture />} />
          <Route path="/blogs2" element={<Blog2 />} />
          <Route path="/blog/:title" element={<BlogDetail />} />
          <Route path="/case-studies/servicenow-casestudy" element={<ServiceStudynew />} />
          <Route path="/services" element={<Service />} />
          <Route path="/insights" element={<Insights />} />
          <Route path="/contact" element={<ContactCA />} />
          <Route path="/contact-old" element={<Contact />} />
          <Route path="/digital-marketing-service" element={<Digitalmarketing />} />
          <Route path="/industries" element={<Industry />} />
          <Route path="/mern-stack-development" element={<Mernstackdevelopment />} />
          <Route path="/ui-ux-design-company" element={<Uiux />} />
          <Route path="/data-science" element={<Datascience />} />
          <Route path="/extendedreality" element={<Extendedreality />} />
          <Route path="/staffing-services" element={<Staffingservices />} />
          <Route path="/recruitment-services" element={<Recrutimentservices />} />
          <Route path="/web-design-and-development-company" element={<Webdevelopment />} />
          <Route path="/eCommerce-web-app-development-company" element={<Ecomdevelopment />} />
          <Route path="/mobile-app-development-company" element={<Mobiledevelopment />} />
          <Route path="/qatesting" element={<Qatesting />} />
          <Route path="/application-services" element={<Applicationservices />} />
          <Route path="/staffing" element={<Staffing />} />
          <Route path="/emerging" element={<Emerging />} />
          <Route path="/itconsulting" element={<Itconsulting />} />
          <Route path="/enterprisesolutions" element={<Enterprisesolutions />} />
          <Route path="/graphics-design-company" element={<Graphicsdesign />} />
          <Route path="/wordpress-development" element={<Wordpressdevelopment />} />
          <Route path="/php-development" element={<Phpdevelopment />} />
          <Route path="/recruitment-service" element={<RecruitmentService />} />
          <Route path="/staff-augementation-service-company" element={<StaffAugementation />} />
          <Route path="/industries/healthcare" element={<HealthCare />} />
          <Route path="/industries/Hospitility" element={<Hospitility />} />
          <Route path="/industries/banking" element={<Banking />} />
          <Route path="/industries/education" element={<Education />} />
          <Route path="/industries/high-technology" element={<HighTechnology />} />
          <Route path="/industries/retail" element={<Retail />} />
          <Route path="/industries/automotive" element={<Automotive />} />
          <Route path="/industries/utilities" element={<Utilities />} />
          <Route path="/industries/insurance" element={<Insurance />} />
          <Route path="/industries/mediaentertainment" element={<MediaEntertainment />} />
          <Route path="/industries/tourtravel" element={<TourTravel />} />
          <Route path="/industries/aerospacedefence" element={<AerospaceDefence />} />
          <Route path="/career" element={<Career />} />
          <Route path="/search-engine-optimization-services-company" element={<SearchEngineOptimization />} />
          <Route path="/pay-per-click-service" element={<PayPerClick />} />
          <Route path="/blockchain-development-company" element={<BlockChain />} />
          <Route path="/ai-and-ml" element={<Cognitivecomputing />} />
          <Route path="/automation" element={<Automation />} />
          <Route path="/cloud-service-solution" element={<Cloud />} />
          <Route path="/artificial-intelligence-service" element={<AI />} />
          <Route path="/social-media-marketing-services" element={<SocialMediaMarketing />} />
          <Route path="/content-marketing-services" element={<ContentMarketing />} />
          <Route path="/email-marketing-services" element={<EmailMarketing />} />
          <Route path="/custom-software-development-services" element={<CustomDevelopment />} />
          <Route path="/content-management-development-services" element={<CMS />} />
          <Route path="/api-integration-services-company" element={<API />} />
          <Route path="/salesforce-services" element={<Salesforce />} />
          <Route path="/digital-transformation-consulting" element={<DigitalTransformation />} />
          <Route path="/devops-services" element={<DevOps />} />
          <Route path="/workday-services" element={<Workday />} />
          <Route path="/jira" element={<Jira />} />
          <Route path="/kronos-consulting-services" element={<Kronos />} />
          <Route path="/servicenow-consulting-services" element={<ServiceNow />} />
          <Route path="/consulting-services" element={<Consulting />} />
          <Route path="/case-studies" element={<CaseStudy />} />
          <Route path="/case-studies/case-study-workday" element={<Casestudyworkday />} />
          <Route path="/case-studies/case-study-devops" element={<Casestudydevops />} />
          <Route path="/case-studies/case-study-salesforce" element={<Casestudysalesforce />} />
          <Route path="/case-studies/case-study-servicenow" element={<Casestudyservicenow />} />
          <Route path="/harsh-keshare" element={<Information />} />
          <Route path="/amit-dubey" element={<Amitsir />} />
          <Route path="/Himanshu-pritmani" element={<Himanshu />} />
          <Route path="/privacy-policy" element={<PrivacyStatement />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/login" element={<Login />} />
          <Route path="/aws/angular" element={<Awsblog />} />
          <Route path="/login-dev" element={<LogApi />} />
          <Route path="/contactNew-Dashboard" element={<ContactNewDashboard />} />
          <Route path="/career-Dashboard" element={<CareerDashboard />} />
          <Route path="/Home-Dashboard" element={<HomeContactDashboard />} />
          <Route path="/SideBar-Dashboard" element={<SideBar />} />
          <Route path="/carr-form" element={<Carr />} />
          <Route path="/NewHomeDash" element={<NewHomeDash />} />
          <Route path="/admin-dashboard" element={<NewContactDash />} />
          <Route path="/NewCareerDash" element={<NewCareerDash />} />
          <Route path="/projectlogin" element={<Projectlogin />} />
          <Route path="/projects" element={<Dashboard />} />
          <Route path="/test" element={<Test />} />
          <Route path="/AddBlog" element={<AddBlog />} />
          <Route path="/ManageBlog" element={<ManageBlog />} />
          <Route path="/AddJob" element={<AddJob />} />
          <Route path="/ManageJob" element={<ManageJob />} />
          <Route path="/AllJobApplications" element={<AllJobApplications />} />
          <Route path="/company-profile" element={<Companydashboard />} />
        </Routes>
        <GoToTop />
        {shouldShowHeaderFooter && <Footer />}
      </React.Suspense>
    </>
  );
}

export default AppContent;
