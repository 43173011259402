import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import AppContent from './AppContent'; // We'll create this component

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;