import React from 'react';

export default function PolicyModal(props) {

    const closeModal = () => {
        props.closeModal();
    };

    return <>
        <div className="modal fade show terms_modal" tabIndex="-1" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content p-4">
                    <div className="modal-header">
                        <button type="button" className="btn-close" onClick={() => closeModal()}></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="policy_wrap mb-5">
                                    <div>
                                        <h5>Cookie Policy for Dwellfox</h5>
                                        <p>Dwellfox emphasises that user security and privacy are critical for establishing trust.
                                            We  are open and transparent with our users about our company and the way we employ technology.
                                            Our cookie policy depicts why we capture the cookie capturing system & how its being used.
                                            When you use or visit our website, our system records the cookies that are stored on and accessible by your device.</p>
                                        <p>
                                            We obtain user consent before acquiring cookies. When you click "Accept" on the Cookie banner, you are giving us your permission to store and access Cookies and Other Tracking Technologies in the manner stated in this Cookie Policy.
                                        </p>
                                        <ul>
                                            <li className='h5'>How does our Website make use of Cookies?</li>
                                            <p>We obtained user information through browser cookies in order to establish audience insights and streamline operations accordingly.
                                                The information obtained through cookies is used to enrich and optimise the user experience. It is also used in advertisements to efficiently target the best of interests.
                                            </p>
                                            <li className='h5'>Cookies We Used </li>
                                            <p>We have outlined the specific categories of cookies that are used on our website, in addition to other pertinent characteristics.</p>
                                            <li className='h5'>Essential Cookies:</li>
                                            <p>Essential cookies as the name suggests are essential for website functionality. Additionally, cookies help our website remember a user's prior actions throughout the course of their browsing session.</p>
                                            <li className='h5'>Cookies to improve performance</li>
                                            <p>This cookie collection is often made up of third-party cookies that collect data on our behalf in order to provide us with usage statistics. Additionally, third-party providers may have access to the cookie and help us determine use, which improves speed.</p>
                                            <li className='h5'>Advertising and retargeting cookies</li>
                                            <p>Certain cookies are acquired from browsers to record user interaction with websites, browsing path, and behaviour. This cookies is also used in advertising, allowing advertisers to target and retarget based on relevant data.</p>
                                            <li className='h5'>Functionality cookies </li>
                                            <p>Functionality cookies keep track of visitor choices, such as language or area, within the system. Additionally, this cookie enables the usage of the information to provide customers with better, more individualised experiences.</p>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal-backdrop fade show"></div>
    </>;
}
