import React, { useRef, useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha"
import emailjs from "@emailjs/browser";
import PolicyModal from "./../Modal/PolicyModal";
import { Helmet } from "react-helmet";
import Multiselect from "multiselect-react-dropdown";
import { Link } from "react-router-dom";
import { BiMap } from "react-icons/bi";
import AOS from "aos";
import "aos/dist/aos.css";


const Contact = () => {
  const form = useRef();
  // const [formvalue, setFormvalue] = useState({firstname:'',lastname:'',email:'',phone:'',company:'',message:''});
   const [firstname,setFirstname]=useState("")
   const [firstnameErr,setFirstnameErr]=useState(false)
   const [lastname,setLastname]=useState("")
   const [lastnameErr,setLastnameErr]=useState(false)
   const [email,setEmail]=useState("")
   const [emailErr,setEmailErr]=useState(false)
   const [phone,setPhone]=useState("")
   const [phoneErr,setPhoneErr]=useState(false)
   const [company,setCompany]=useState("")
   const [companyErr,setCompanyErr]=useState(false)
   const [message,setMessage]=useState("")
   const [messageErr,setMessageErr]=useState(false)
   const [varfied,SetVarfied] = useState(false)


  const [formerror,setFormerror] = useState({})
  const [issbmit, setSubmit]= useState(false)
  const [modalPrivacy, setModalPrivacy] = useState(false);
  const [options, setoptions] = useState([
    "Web Development",
    "Mobile App Development",
    "UI/UX Designing",
    "Staff Augumentation",
    "IT Consulting",
    "IT Recruitment",
    "Digital Marketing",
  ]);

  function sendEmail(e){

    // if(firstname.length<2 || lastname.length<2 || email.length<2 || phone.length<10 || company.length<2 || message.length<2 ){
    //      alert("type correct value")
    // }else{
    //   alert("all good")
    // }
    e.preventDefault();
    // setFormerror(validationform(formvalue));
    // setSubmit(true);

    emailjs
    
      .sendForm(
        "service_kvim19s",
        "template_tauw1h4",
        form.current,
        "8PvXExowwhr9S4uc-"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("Message Send");
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };


  function firstnamevalidation(e){
    // const {name,value}=e.target;
    // setFormvalue({...formvalue,[name]: value});
    
      let item = e.target.value;
      if(item.length<2){
        setFirstnameErr(true)
      }else{
        setFirstnameErr(false);
      }
        setFirstname(item)
      console.log(e.target.value.length)
    

  }

  function lastnamevalidation(e){
    // const {name,value}=e.target;
    // setFormvalue({...formvalue,[name]: value});
    
      let item = e.target.value;
      if(item.length<2){
        setLastnameErr(true)
      }else{
        setLastnameErr(false);
      }
      setLastname(item)
      console.log(e.target.value.length)
    

  }

  function emailvalidation(e){
    // const {name,value}=e.target;
    // setFormvalue({...formvalue,[name]: value});
    
      let item = e.target.value;
      if(item.length<2){
        setEmailErr(true)
      }else{
        setEmailErr(false);
      }
      setEmail(item)
      console.log(e.target.value.length)
  }

  function phonevalidation(e){
    // const {name,value}=e.target;
    // setFormvalue({...formvalue,[name]: value});
    
      let item = e.target.value;
      if(item.length<10){
        setPhoneErr(true)
      }else{
        setPhoneErr(false);
      }
      setPhone(item)
      console.log(e.target.value.length)
    

  }

  function companyvalidation(e){
    // const {name,value}=e.target;
    // setFormvalue({...formvalue,[name]: value});
    
      let item = e.target.value;
      if(item.length<2){
        setCompanyErr(true)
      }else{
        setCompanyErr(false);
      }
      setCompany(item)
      console.log(e.target.value.length)
    

  }

  function messagevalidation(e){
    // const {name,value}=e.target;
    // setFormvalue({...formvalue,[name]: value});
    
      let item = e.target.value;
      if(item.length<2){
        setMessageErr(true)
      }else{
        setMessageErr(false);
      }
      setMessage(item)
      console.log(e.target.value.length)
    

  }

  useEffect(() => {
    AOS.init();
  }, []);

  function onChange(value){
    console.log("captcha value:",value)
    SetVarfied(true);
  }
  

  return (
    <>
      <Helmet>
        <meta httpEquiv="Content-Type" content="text/html" charSet="utf-8" />
        <title>Contact Us - Dwellfox</title>
        <meta
          name="description"
          content="Contact us for IT service and solutions"
        />
        <meta property="og:url" content="https://dwellfox.com/contact" />
        <meta property="og:title" content="Contact Us - Dwellfox " />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="Assets/contactus_banner.webp" />
        <meta
          property="og:description"
          content="Contact us for IT service and solutions "
        />
        <meta name="keywords" content="" />
      </Helmet>

      {modalPrivacy ? (
        <PolicyModal closeModal={() => setModalPrivacy(false)} />
      ) : (
        ""
      )}

      {/* ------------First section start here---------- */}
      <div className="container-fluid contactsection1">
        <div className="container text-white banner">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
              <h1>Contact Us</h1>
              <p className="contact-main-head">
                Connect with us to receive the best customer experience and the
                best development technologies.
              </p>
            </div>
          </div>
        </div>
      </div>


      <section className="container-fluid contact-background-section">
        <div className="container px-lg-5 py-5" style={{ maxWidth: "950px" }}>
          <div className="row">
            <h2 className="contact-heading">
              Experience right now. Get in touch with us to see how we can
              Create WOW! for you.
            </h2>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 py-4">
              <div className="contact-form-section py-4 px-4">
                <form ref={form} onSubmit={sendEmail}  className="mt-2 p-lg-5">
                  <div className="row mb-2">
                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 mb-3">
                      <div className="form-outline">
                        <label
                          htmlFor=""
                          className="mb-3"
                          style={{ fontSize: "18px" }}
                        >
                          First name<sup className="text-danger">*</sup>
                        </label>
                        <input
                          required
                          name="user_first_name"
                        
                          placeholder="First name"
                          type="text"
                          // id="form6Example1"
                          className="form-control"
                          size="40"
                          style={{
                            height: "50px",
                            border: "1px solid #d2d2d2",
                            borderRadius: "4px",
                            transition: ".3s",
                            padding: "5px 15px",
                            fontSize: "16px",
                            width: "100%",
                          }}
                          // value={formvalue.firstname}
                          onChange={firstnamevalidation}
                        />
                        {firstnameErr?<span className="text-danger">Please Enter First Name</span>:null}
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                      <div className="form-outline">
                        <label
                          htmlFor=""
                          className="mb-3"
                          style={{ fontSize: "18px" }}
                        >
                          Last name<sup className="text-danger">*</sup>
                        </label>
                        <input
                          required
                          placeholder="Last name"
                          name="user_last_name"
                          type="text"
                          // id="form6Example5"
                          className="form-control"
                          size="40"
                          style={{
                            height: "50px",
                            border: "1px solid #d2d2d2",
                            borderRadius: "4px",
                            transition: ".3s",
                            padding: "5px 15px",
                            fontSize: "16px",
                            width: "100%",
                          }}
                          // value={formvalue.lastname}
                          onChange={lastnamevalidation}
                        />
                     {lastnameErr?<span className="text-danger">Please Enter Last Name</span>:null}

                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="form-outline">
                        <label
                          htmlFor=""
                          className="mb-3"
                          style={{ fontSize: "18px" }}
                        >
                          Email<sup className="text-danger">*</sup>
                        </label>
                        <input
                          required
                          name="user_email"
                          placeholder="you@company.com"
                          type="email"
                          id="form6Example1"
                          className="form-control"
                          size="40"
                          style={{
                            height: "50px",
                            border: "1px solid #d2d2d2",
                            borderRadius: "4px",
                            transition: ".3s",
                            padding: "5px 15px",
                            fontSize: "16px",
                            width: "100%",
                          }}
                          // value={formvalue.email}
                          onChange={emailvalidation}
                        />
                         {emailErr?<span className="text-danger">Please Enter Your email</span>:null}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="form-outline">
                        <label
                          htmlFor=""
                          className="mb-3"
                          style={{ fontSize: "18px" }}
                        >
                          Phone number<sup className="text-danger">*</sup>
                        </label>
                        <input
                          type="number"
                          name="user_number"
                          placeholder="+1 (555) 000-0000"
                          required
                          id="form6Example6"
                          className="form-control"
                          size="40"
                          style={{
                            height: "50px",
                            border: "1px solid #d2d2d2",
                            borderRadius: "4px",
                            transition: ".3s",
                            padding: "5px 15px",
                            fontSize: "16px",
                            width: "100%",
                          }}
                          // value={formvalue.phone}
                          onChange={phonevalidation}
                        />
                         {phoneErr?<span className="text-danger">Please Enter Your number</span>:null}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="form-outline">
                        <label
                          htmlFor=""
                          className="mb-3"
                          style={{ fontSize: "18px" }}
                        >
                          Company<sup className="text-danger">*</sup>
                        </label>
                        <input
                          type="text"
                          name="user_company"
                          placeholder=""
                          id="form6Example6"
                          className="form-control"
                          size="40"
                          style={{
                            height: "50px",
                            border: "1px solid #d2d2d2",
                            borderRadius: "4px",
                            transition: ".3s",
                            padding: "5px 15px",
                            fontSize: "16px",
                            width: "100%",
                          }}
                          // value={formvalue.company}
                          onChange={companyvalidation}
                        />
              {companyErr?<span className="text-danger">Please Enter Company Name</span>:null}

                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="form-outline">
                        <label
                          htmlFor=""
                          className="mb-3"
                          style={{ fontSize: "18px" }}
                        >
                          Message<sup className="text-danger">*</sup>
                        </label>
                        <textarea
                          placeholder=""
                          name="message"
                          required
                          className="form-control"
                          id="form6Example7"
                          rows="4"
                          // value={formvalue.message}
                          onChange={messagevalidation}
                        ></textarea>
                         {messageErr?<span className="text-danger">Please Enter Message</span>:null}
                      </div>
                    </div>
                  </div>

                  <div className="row align-items-center mb-2">
                    <div className="col-md-4 mb-3">
                      <h3 className="contact-heading-section ">
                        You are interested in :
                      </h3>
                    </div>
                    <div className="col-md-4 mb-3">
                      <Multiselect
                        name="user_services"
                        isObject={false}
                        options={options}
                        showCheckbox
                        closeOnSelect={true}
                        showArrow={true}
                        disable={false}
                        hidePlaceholder={true}
                        closeIcon="cancel"
                        alt="dropdown"
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="form-outline">
                        <input
                          type="checkbox"
                          name="user_policy"
                          placeholder=""
                          required
                          id="form6Example6"
                          className="me-2"
                        />
                        <span>
                          You agree to our friendly{" "}
                          <Link
                            to="/privacy-policy"
                            className="text-decoration-none"
                            onClick={() => setModalPrivacy(true)}
                          >
                            privacy policy.
                          </Link>
                        </span>
                      </div>
                    </div>
                  </div>
                   {/* <ReCAPTCHA
                   sitekey="6LeegEIkAAAAAIZB5gNhezbFrEcP67r8IR_wptAi"
                   onChange={onChange}
                   /> */}

                  <div className="col-sm-12 pt-4 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-3 pb-0 d-grid">
                    <input  style={{color:'gray'}}
                      type="submit"
                      value="Send Message"
                      className="form-control d-block contact-submit-button"
                      // disabled={!varfied}
                      // onClick={firstnamevalidation}
                    />
                    {/* <button className="btn btn-success" name="button" type="submit" onClick={firstnamevalidation} >Submit</button> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ------------second section end here---------- */}

      {/* ------------Third section start here---------- */}
      <div className='container-fluid'>
        <div className="row">
          <h2 data-aos="fade-up" className="aos-init aos-animate location-heading">Our Locations</h2>
          <div className="col-sm-6 col-md-6 col-lg-12 col-xl-12 col-xxl-12 m-auto p-0">
            <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
              <div className='d-flex justify-content-center align-items-center flex-wrap '>
              <button className="nav-link active mb-3 pb-5 aos-init aos-animate button-location2" data-aos="zoom-in" data-aos-delay="200" data-aos-offset="0" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false" style={{ "marginRight": "10px", "border": "1px solid black", "width": "248px", "height": "200px" }}>
                  <div className='d-flex justify-content-between align-items-center' style={{ "textAlign": "left" }}>
                    <BiMap size={40} />
                    <img
                      className="mapimg"
                      src="/Assets/united-states.webp"
                      alt="US"
                    />
                  </div>
                  <p className='map-heading-section'>205 Van Buren Street Suite 120 #1035
Herndon, VA 20170, United States</p>
                </button>
                <button className="nav-link mb-3 aos-init aos-animate button-location1" data-aos="zoom-in" data-aos-delay="100" data-aos-offset="0" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false" style={{ "marginRight": "10px", "border": "1px solid black", "width": "248px", "height": "200px" }}>
                  <div className='d-flex justify-content-between align-items-center' style={{ "textAlign": "left" }}>
                    <BiMap size={40} />
                    <img
                      className="mapimg"
                      src="/Assets/UAE.jpg"
                      alt="UAE"
                    />
                  </div>
                  <p className='map-heading-section'>Dubai Silicon Oasis, DDP, Building A2, Dubai, United Arab Emirates</p>
                </button>
                <button className="nav-link mb-3 aos-init aos-animate button-location" data-aos="zoom-in" data-aos-delay="300" data-aos-offset="0" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true" style={{ "marginRight": "10px", "border": "1px solid black", "width": "248px", "height": "200px", " backgroundImage": "url(../public/Assets/digital-transformation-banner.webp)" }}>
                  <div className='d-flex justify-content-between align-items-center' style={{ "textAlign": "left" }}>
                    <BiMap size={40} />
                    <img
                      className="mapimg"
                      src="/Assets/india.webp"
                      alt="India"
                    />
                  </div>
                  <p className='map-heading-section'>405, Apollo Premier, Vijay Nagar Square, Indore, Madhya Pradesh 452010</p>
                </button>
                <button className="nav-link mb-3 pb-4 aos-init aos-animate button-location3" data-aos="zoom-in" data-aos-delay="400" data-aos-offset="0" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false" style={{ "marginRight": "10px", "border": "1px solid black", "width": "248px", "height": "200px" }}>
                  <div className='d-flex justify-content-between align-items-center' style={{ "textAlign": "left" }}>
                    <BiMap size={40} />
                    <img
                      className="mapimg"
                      src="/Assets/canada.webp"
                      alt="canada"
                    />
                  </div>
                  <p className='map-heading-section'>2164 Montreal Road Unit 5 PMB 1045 Ottawa, Ontario K1J 1G4 Canada</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h2 className="text-center pt-5 pb-4 location-heading">Find us on Google Maps</h2>
      <div className="tab-content pb-5" id="v-pills-tabContent">
        <div className="tab-pane fade" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14718.423918390577!2d75.8866546800621!3d22.742882111525017!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fdc9312d8d1f%3A0x97a28e1e6f9e4b6a!2sDwellfox%20Private%20Limited!5e0!3m2!1sen!2sin!4v1669097575114!5m2!1sen!2sin"
            width="600" style={{ width: "100%", height: "350px" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3612.498718221887!2d55.37569511493205!3d25.118824783932666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6570d49d452f%3A0x5993cfcf524af8a7!2sIFZA%20DDP%20Building%20A2!5e0!3m2!1sen!2sin!4v1672120388032!5m2!1sen!2sin" width="600" height="450" style={{ width: "100%", height: "350px" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade">
          </iframe>
        </div>
        <div className="tab-pane fade show active" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3102.6145134107755!2d-77.39120228471309!3d38.95563407956162!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b647e54d24f0a7%3A0xe64b933cabceaf0a!2s205%20Van%20Buren%20St%20120%201035%2C%20Herndon%2C%20VA%2020170%2C%20USA!5e0!3m2!1sen!2sin!4v1678954583080!5m2!1sen!2sin" width="600" height="450" style={{ width: "100%", height: "350px" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
         
        </div>
        <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d174.94087246539056!2d-75.58938943711186!3d45.44856875358341!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cce117d64af55c1%3A0x717f44741d49f254!2sSterling%20Spaces!5e0!3m2!1sen!2sin!4v1674459353052!5m2!1sen!2sin" width="600" height="450" style={{ width: "100%", height: "350px" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      {/* ------------Third section end here---------- */}
    </>
  );
}

export default Contact;